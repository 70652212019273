import React, { useState } from "react";
import "./PresentationDashboard.css";
import Search from "../assets/search.svg";
import Maximize from "../assets/maximize.svg";
import Minimize from "../assets/minimize.svg";
import Presentation from "./Presentation";
import ChevronDown from "../assets/chevron-down.svg";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "../redux/slices/sidebarSlices";

const PresentationDashboard = () => {
  const dispatch = useDispatch();
  const [filterOption, setFilterOption] = useState("Approved");
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchContainer, setShowSearchContainer] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("On Demand Presentation");

  const options = [
    { label: "On Demand Presentation", value: "Approved" },
    { label: "Pending Presentation", value: "Pending" },
    { label: "Archived Presentation", value: "Archived" },
  ];

  const handleFilterChange = (option) => {
    setFilterOption(option.value);
    setSelectedOption(option.label);
    setShowDropdown(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchIconClick = () => {
    setShowSearchContainer(!showSearchContainer);
  };

  return (
    <div className="presentation-dashboard">
      <div className="overlap">
        <div
          className="on-demand-container"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <div className="on-demand-text">{selectedOption}</div>
          <img
            className="dropdown-icon"
            src={ChevronDown}
            alt="Dropdown Icon"
          />
        </div>
        {showDropdown && (
          <div className="dropdown-container">
            <ul className="options">
              {options.map((option) => (
                <li
                  key={option.value}
                  onClick={() => handleFilterChange(option)}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        )}
        {showSearchContainer ? (
          <div className="search-container">
            <input
              type="text"
              placeholder="Search Title.."
              value={searchQuery}
              onChange={handleSearchChange}
              className="search-input"
            />
          </div>
        ) : (
          <></>
        )}
        <img
          className="search"
          alt="Search"
          src={Search}
          onClick={handleSearchIconClick}
        />
        <img className="layer" alt="Maximize" src={Maximize} />
        <img
          className="dash"
          src={Minimize}
          alt="Minimize"
          onClick={() => dispatch(toggleSidebar())}
        />
        <div className={`presentation-div ${showDropdown ? 'blur-background' : ''}`}>
          <Presentation filterOption={filterOption} searchQuery={searchQuery} />
        </div>
      </div>
    </div>
  );
};

export default PresentationDashboard;
