import React from "react";
import "./PresentationButton.css";
import Polygon from "../assets/polygon.svg";

const PresentationButton = ({ onClick }) => {
  return (
    <div className="presentation-btn" onClick={onClick}>
      <div className="presentation-overlap">
        <div className="polygon-wrapper">
          <img className="polygon" alt="Polygon" src={Polygon} />
        </div>
        <div className="presentation-text">Presentation</div>
      </div>
    </div>
  );
};

export default PresentationButton;
