import React from "react";
import "./EvaButton.css";
import Eva from "../assets/eva.jpeg";

const EvaButton = ({ onClick }) => {
  return (
    <div className="eva-btn" onClick={onClick}>
      <div className="rectangle" />
      <img className="ellipse" alt="EVA" src={Eva} />
      <div className="eva-text">EVA</div>
    </div>
  );
};

export default EvaButton;