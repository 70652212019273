import React, { useState, useEffect } from "react";
import PresentationImage from "../assets/genai.png";
import "./Presentation.css";
import axios from "axios";
import dataJson from "../data.json";
const API_KEY = "e1a38197a2c0fa11176188725b6e206dc824203f";

const Presentation = ({ filterOption, searchQuery }) => {
  const [selectedTopics, setSelectedTopics] = useState({});
  const [showAllTopics, setShowAllTopics] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchPresentationData();
  }, [filterOption, searchQuery]);

  // const fetchPresentationData = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://sinarmas-api.azurewebsites.net/api/scenario/get/",
  //       {
  //         headers: {
  //           "X-API-Key": API_KEY,
  //           accept: "application/json",
  //         },
  //       }
  //     );
  //     if (response.ok) {
  //       const responseBody = await response.json();
  //       const data = responseBody.data;
  //       const filteredData = data.filter((item) =>
  //         item.name.toLowerCase().includes(searchQuery.toLowerCase())
  //       );
  //       console.log("data", filteredData)
  //       setData(filteredData);
  //       initializeSelectedTopics(filteredData);
  //     } else {
  //       console.error("Failed to fetch presentation data:", response.status);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching presentation data:", error);
  //   }
  // };

  const fetchPresentationData = async () => {
    try {
      const data = dataJson;
      const filteredData = data.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setData(filteredData);
      initializeSelectedTopics(filteredData);
    } catch (error) {
      console.error("Error fetching presentation data:", error);
    }
  };

  const initializeSelectedTopics = (data) => {
    const headers = Array.from(new Set(data.map((item) => item.header)));
    const initialSelectedTopics = {};
    headers.forEach((header) => {
      const topics = data
        .filter(
          (item) => item.header === header && item.status === filterOption
        )
        .map((item) => item.topic);
      initialSelectedTopics[header] = topics.length > 0 ? topics[0] : null;
    });
    setSelectedTopics(initialSelectedTopics);
  };

  const handleTopicClick = (header, topic) => {
    setSelectedTopics({ ...selectedTopics, [header]: topic });
    // Reset showAllTopics when changing topic
    setShowAllTopics({ ...showAllTopics, [header]: false });
  };

  const handleSeeAllClick = (header) => {
    setShowAllTopics({ ...showAllTopics, [header]: true });
  };

  const handleImageClick = async (scenarioId) => {
    try {
      const voiceOverResponse = await fetch(`https://sinarmas-api.azurewebsites.net/api/voice-over/get/${scenarioId}`, {
        headers: {
          'accept': 'application/json',
          'X-API-Key': API_KEY,
        }
      });
      if (!voiceOverResponse.ok) {
        throw new Error('Failed to fetch voice over ID');
      }
      const voiceOverData = await voiceOverResponse.json();
      const voiceOverId = voiceOverData.data[0].id;
  
      const startScenarioResponse = await fetch("https://sinarmas-api.azurewebsites.net/api/scenario/start_scenario/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': API_KEY,
        },
        body: JSON.stringify({ scenario_id: scenarioId, voice_over_id: voiceOverId })
      });
  
      if (!startScenarioResponse.ok) {
        const username = prompt('Enter your username:');
        const password = prompt('Enter your password:');

        const updatePasswordResponse = await fetch("https://sinarmas-api.azurewebsites.net/api/scenario/update-uipath-pass/", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': API_KEY,
          },
          body: JSON.stringify({ username: username, password: password })
        });

        if (!updatePasswordResponse.ok) {
          throw new Error('Failed to update UIPath password');
        }
  
        console.log(`UIPath password updated successfully`);
      } else {
        console.log(`Scenario ${scenarioId} started successfully`);
      }
    } catch (error) {
      console.error('Error starting scenario:', error);
    }
  };

  return (
    <div>
      {Object.entries(selectedTopics).map(([header, selectedTopic], index) => {
        const topicsAvailable = data.some(
          (item) => item.header === header && item.status === filterOption
        );
        if (topicsAvailable) {
          return (
            <PresentationHeader
              key={index}
              header={header}
              selectedTopic={selectedTopic}
              onTopicClick={handleTopicClick}
              onSeeAllClick={() => handleSeeAllClick(header)}
              showAll={showAllTopics[header]}
              filterOption={filterOption}
              data={data}
              onImageClick={handleImageClick}
            />
          );
        } else {
          return (
            <div className="no-videos-message" key={index}>
              No presentation yet
            </div>
          );
        }
      })}
    </div>
  );
};

const PresentationHeader = ({
  header,
  selectedTopic,
  onTopicClick,
  onSeeAllClick,
  showAll,
  filterOption,
  data,
  onImageClick,
}) => {
  const topics = Array.from(
    new Set(
      data
        .filter(
          (item) => item.header === header && item.status === filterOption
        )
        .map((item) => item.topic)
    )
  );
  const filteredData = data.filter(
    (item) =>
      item.header === header &&
      item.topic === selectedTopic &&
      item.status === filterOption
  );

  useEffect(() => {
    if (!topics.includes(selectedTopic)) {
      const newSelectedTopic = topics.length > 0 ? topics[0] : null;
      onTopicClick(header, newSelectedTopic);
    }
  }, [header, selectedTopic, topics, onTopicClick]);

  const topicsToRender = showAll ? filteredData : filteredData.slice(0, 4);

  return (
    <div className="presentation-group">
      <h2 className="presentation-header">{header}</h2>
      <TopicButtons
        topics={topics}
        selectedTopic={selectedTopic}
        onTopicClick={(topic) => onTopicClick(header, topic)}
        onSeeAllClick={onSeeAllClick}
        showAll={showAll}
      />
      <TopicImages data={topicsToRender} onImageClick={onImageClick} />
    </div>
  );
};

const TopicButtons = ({
  topics,
  selectedTopic,
  onTopicClick,
  onSeeAllClick,
  showAll,
}) => {
  return (
    <div className="topics">
      {topics.map((topic, index) => (
        <div
          className={`topics-button ${topic === selectedTopic ? "active" : ""}`}
          key={index}
          onClick={() => onTopicClick(topic)}
        >
          <div className="topics-button-text"> {topic} </div>
        </div>
      ))}
      {!showAll && (
        <div className="see-all-button" onClick={onSeeAllClick}>
          See All &gt;
        </div>
      )}
    </div>
  );
};

const TopicImages = ({ data, onImageClick }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [actionType, setActionType] = useState("");

  const handleApproval = async (scenarioId) => {
    setItemId(scenarioId);
    setActionType("approve");
    setShowConfirmationModal(true);
  };

  const handleReject = async (scenarioId) => {
    setItemId(scenarioId);
    setActionType("reject");
    setShowConfirmationModal(true);
  };

  const confirmAction = async () => {
    if (actionType === "approve") {
      try {
        const response = await axios.post(
          "https://sinarmas-api.azurewebsites.net/api/scenario/update-status/",
          {
            scenario_id: itemId,
            status: "Approved",
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-API-Key": API_KEY,
            },
          }
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error approving scenario:", error);
      }
    } else if (actionType === "reject") {
      try {
        const response = await axios.delete(
          `https://sinarmas-api.azurewebsites.net/api/scenario/delete/${itemId}`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-API-Key": API_KEY,
            },
          }
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error rejecting scenario:", error);
      }
    }

    setShowConfirmationModal(false);
  };

  const playPresentation = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="presentations">
      {data.map((item, index) => (
        <div key={index} className="presentation-item">
          <img
            className="presentation-image"
            src={item.image_url || PresentationImage}
            alt={item.image_title}
            onClick={() => playPresentation(item.video_url)}
          />
          <div className="content">
            {item.image_url ? (
              <div className="text">{item.image_title}</div>
            ) : (
              <></>
            )}
          </div>
          <div className="presentations-text">{item.name}</div>
          {item.status === "Pending" && (
            <div className="presentation-buttons">
              <button
                className="approval-button"
                onClick={() => handleApproval(item.id)}
              >
                Publish
              </button>
              <button
                className="reject-button"
                onClick={() => handleReject(item.id)}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      ))}

      {showConfirmationModal && (
        <div className="modal">
          <div className="modal-content">
            <p>
              Are you sure you want to{" "}
              {actionType === "approve" ? "publish" : "delete"} this
              presentation?
            </p>
            <button onClick={confirmAction}>
              {actionType === "approve" ? "Publish" : "Delete"}
            </button>
            <button onClick={() => setShowConfirmationModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Presentation;
