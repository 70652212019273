import React, { useState } from "react";
import "./App.css";
import EvaButton from "./EvaButton/EvaButton";
import PresentationButton from "./PresentationButton/PresentationButton";
import EvaDashboard from "./EvaDashboard/EvaDashboard";
import PresentationDashboard from "./PresentationDashboard/PresentationDashboard";
import { toggleSidebar } from "./redux/slices/sidebarSlices";
import { useSelector, useDispatch } from 'react-redux';

const SlidingDiv = () => {
  const isSidebarOpen = useSelector((state) => state.sidebar.isSidebarOpen);
  const [isEva, setEva] = useState(true);
  const dispatch = useDispatch();

  const toggleEva = () => {
    if(isSidebarOpen && !isEva){
        setEva(true);
      } else {
        dispatch(toggleSidebar());
        setEva(true)
      }
  };

  const togglePresentation = () => {
    if(isSidebarOpen && isEva){
      setEva(false);
    } else {
      dispatch(toggleSidebar());
      setEva(false);
    }
  };

  const sidebarWidth = 2040;

  return (
    <>
    <div className="buttons">
      <EvaButton onClick={toggleEva} />
      <PresentationButton onClick={togglePresentation} />
    </div>
    <div
      className={`sidebar ${isSidebarOpen ? "open" : ""}`}
      style={{
        right: `${isSidebarOpen ? "0" : `-${sidebarWidth}px`}`,
      }}
    >
      {isEva ? <EvaDashboard /> : <PresentationDashboard />}
    </div>
    </>
  );
};

export default SlidingDiv;
