import SlidingDiv from "../SlidingDiv";
import "./MVDWarroom.css";

function App() {
  const iframeStyle = {
    width: "100%",
    height: "100vh",
    borderWidth: "0",
  };

  return (
    <div className="App" style={{ position: "relative" }}>
      <SlidingDiv />
      <iframe
        title="MVD WarRoom_20231214"
        src="https://app.powerbi.com/reportEmbed?reportId=cc33908d-058c-4c61-9a80-31f4deabc318&autoAuth=true&ctid=ef05516d-1c43-414d-98c1-a5e469d028b2&pageName=ReportSection702810f9d3e00bd82710&navContentPaneEnabled=false"
        allowFullScreen={true}
        style={iframeStyle}
      ></iframe>
    </div>
  );
}

export default App;
