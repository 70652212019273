import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from "./LoginPage/LoginPage"
import Homepage from "./Homepage/Homepage";
import MVDWarroom from './MVDWarroom/MVDWarroom';
import MVDInvestor from './MVDInvestor/MVDInvestor';
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/warroom" element={<MVDWarroom/>} />
        <Route path="/investor" element={<MVDInvestor/>} />
      </Routes>
    </Router>
  );
}

export default App;