import React, { useEffect } from "react";
import SlidingDiv from "../SlidingDiv";
import { useNavigate } from "react-router-dom";
import "./MVDInvestor.css";

function MVDInvestor() {
  const iframeStyle = {
    width: "100%",
    height: "100vh",
    borderWidth: "0",
  };

  return (
    <div className="App" style={{ position: "relative" }}>
      <SlidingDiv />
      <iframe
        title="MVD Investor"
        src="https://app.powerbi.com/reportEmbed?reportId=3d26006c-9dd6-4cdb-9f25-dbfe61a5bdc7&autoAuth=true&ctid=ef05516d-1c43-414d-98c1-a5e469d028b2"
        allowFullScreen={true}
        style={iframeStyle}
      ></iframe>
    </div>
  );
}

export default MVDInvestor;
