import React from "react";
import "./EvaDashboard.css";
import Eva from "../assets/eva.jpeg";
import Minimize from "../assets/minimize.svg"
import Bot from "../Bot"
import Maximize from "../assets/maximize.svg"
import { useDispatch } from 'react-redux';
import { toggleSidebar } from '../redux/slices/sidebarSlices'; 

const EvaDashboard = () => {
  const dispatch = useDispatch();

  return (
    <div className="eva-dashboard">
      <div className="overlap">
        <div className="overlap-wrapper">
          <div className="overlap-group">
            <div className="text-wrapper">Eva</div>
            <img className="ellipse" alt="Ellipse" src={Eva} />
            <div className="group-wrapper">
              <img src={Maximize} alt=""/>
              <img src={Minimize} alt="" onClick={() => dispatch(toggleSidebar())}/>
            </div>
          </div>
        </div>
        <Bot />
      </div>
    </div>
  );
};

// Memoize the Bot component
// const MemoizedBot = React.memo(Bot);

export default EvaDashboard;
